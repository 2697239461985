@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #666 #1e1e1e;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #1e1e1e;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 4px;
  }