.cli-container {
  font-size: 100rem; /* Set your desired font size here */
}

@media (max-width: 768px) {
  .cli-container {
    font-size: 0.55rem; /* Adjust this value as needed */
  }
}

/* Add these rules to your existing CSS file */

/* For webkit-based browsers (Chrome, Safari, etc.) */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #000000;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #333333;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555555;
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #333333 #000000;
}